/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import Input from "antd/lib/input";
import Tooltip from "antd/lib/tooltip";
import Typography from 'antd/lib/typography';
import Select from "antd/lib/select";

const {Text} = Typography
const apiUrl = process.env.REACT_APP_API;
const {Search} = Input;

const Results = () => {

    const [state, setState] = useState({
        errorMessage: '',
        loader: true,
        transparentLoader: false,
        searchValue: '',
        gradeValue: '',
        centerValue: '',
        pp: 50,
        GradesList: [],
        CentersList: []
    })

    const [data, setData] = useState([]);
    const [transparentLoader, setTransparentLoader] = useState(false);
    const [dataTotal, setDataTotal] = useState(0);
    const [page, setPage] = useState(1);
    const [isFetching, setIsFetching] = useState(false);

    useEffect(() => {
        document.body.className = 'page-results';

        getResultsList().then(res => {
            let {code, payload} = res
            if (code === 200) {
                setDataTotal(payload.count)
                setData(payload.list);
            }
        })
        window.addEventListener("scroll", isScrolling);

        return () => {
            document.body.className = '';
            window.removeEventListener("scroll", isScrolling);
        }
    }, []);

    const isScrolling = () => {
        if ((window.innerHeight + window.pageYOffset) >= document.body.offsetHeight - 250) {
            setIsFetching(true)
        }
    }

    useEffect(() => {
        if (isFetching && (dataTotal && dataTotal > data.length)) {
            if (!transparentLoader) {
                setTransparentLoader(true)
            }
            onChange(page);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching]);

    useEffect(() => {
        getGradesList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        GradesList: res
                    }
                })
            }
        })

        getCenterList().then(res => {
            if (res) {
                setState(prevState => {
                    return {
                        ...prevState,
                        CentersList: res,
                    }
                })
            }
        })
        return () => {
        }
    }, []);

    const getGradesList = async () => {
        let res = await axios.get(`${apiUrl}/student/grades`);
        let {data} = res;
        if (data.error === null) {
            return data.payload
        }
    }

    const getCenterList = async () => {
        let queryParams = {}
        queryParams._start = 0
        queryParams._end = 250
        queryParams.search = ''

        let res = await axios.get(`${apiUrl}/student/centers`, {
            params: queryParams
        });
        let {data} = res;

        if (data.error === null) {
            return data.payload
        }
    }

    const getResultsList = async (start = 0, end = 50, grade = '', center = '', name = '', eventName='summer-olymp-2021') => {
        const queryParams = {
            _start: start,
            _end: end,
            grade,
            center,
            name,
            eventName
        };
        const res = await axios.get(`${apiUrl}/web/results/`, {
            params: queryParams,
        })

        const {data} = res;
        if (data.error === null) {
            let {rows, count} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })
            setTransparentLoader(false)
            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                    transparentLoader: false
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        const {pp, searchValue, gradeValue, centerValue} = state;

        const start = data.length,
            end = parseInt(data.length) + pp;

        getResultsList(start, end, gradeValue, centerValue, searchValue).then(res => {
            let {code, payload} = res
            if (code === 200) {
                setData([...data, ...payload.list]);
                setPage(page + 1)
                setIsFetching(false)
            }
        })
    }

    const onSearch = value => {
        let {pp, gradeValue, centerValue} = state;

        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
                transparentLoader: true
            }
        })

        getResultsList(0, pp, gradeValue, centerValue, value).then(res => {
            if (res.code === 200) {
                setPage(1)
                setDataTotal(res.payload.count)
                setData(res.payload.list);
                setIsFetching(false);
            }
        })
    }

    const onGradeChange = value => {
        let {pp, searchValue, centerValue} = state;

        setState(prevState => {
            return {
                ...prevState,
                gradeValue: value,
                transparentLoader: true
            }
        })
        getResultsList(0, pp, value, centerValue, searchValue).then(res => {
            if (res.code === 200) {
                setPage(1)
                setDataTotal(res.payload.count)
                setData(res.payload.list);
                setIsFetching(false);
            }
        })
    }

    const onCenterChange = value => {
        let {pp, searchValue, gradeValue} = state;

        setState(prevState => {
            return {
                ...prevState,
                centerValue: value,
                transparentLoader: true
            }
        })

        getResultsList(0, pp, gradeValue, value, searchValue).then(res => {
            if (res.code === 200) {
                setPage(1)
                setDataTotal(res.payload.count)
                setData(res.payload.list);
                setIsFetching(false);
            }
        })
    }

    const {loader, GradesList, CentersList} = state
    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : ''}

            {transparentLoader ? (
                <div className="loader-box transparent" style={{display: 'flex'}}>
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : ('')}


            <div className="page-container">
                <div className="content-wrapper">
                    <h2>Space Panda Summer Math Olympiad 2021 Results</h2>

                    <div className="filter-box">
                        <div className="field-box">
                            <Search
                                autoComplete={new Date().valueOf()}
                                placeholder="Search by Student Name"
                                onSearch={onSearch}/>
                        </div>
                        <div className="field-box">
                            <Select
                                virtual={false}
                                autoComplete={new Date().valueOf()}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Select grade"
                                allowClear
                                showArrow
                                onChange={onGradeChange}
                            >
                                {Object.keys(GradesList).map((key) => {
                                    let listItem = GradesList[key]
                                    return (
                                        <Select.Option key={key}
                                                       value={listItem.id}>{listItem.label}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                        <div className="field-box">
                            <Select
                                virtual={false}
                                autoComplete={new Date().valueOf()}
                                showSearch
                                optionFilterProp="children"
                                placeholder="Type your zip code, state or math center name"
                                allowClear
                                showArrow
                                onChange={onCenterChange}
                            >
                                <Select.Option key={'SpacePandaOnline'}
                                               value="Space Panda Online">Space Panda Online</Select.Option>
                                {Object.keys(CentersList).map((key) => {
                                    let {centerName, state, zipCode} = CentersList[key]
                                    return (
                                        <Select.Option key={key}
                                                       value={centerName}>{centerName}, {state}, {zipCode}</Select.Option>
                                    )
                                })}
                            </Select>
                        </div>
                    </div>

                    {data.length ? (
                        <div className="results-list-box">
                            <div className="results-list-header">
                                <div className="name">Student Name</div>
                                <div className="grade">Grade</div>
                                <div className="state">State</div>
                                <div className="center">Center Name</div>
                                <div className="rank">Rank</div>
                                <div className="award">Award</div>
                            </div>
                            <div className="results-list">
                                {Object.keys(data).map(listKey => {
                                    const {
                                        award,
                                        centerName,
                                        gradeName,
                                        rankForFront,
                                        state: province,
                                        studentFName,
                                        studentLName,
                                    } = data[listKey]

                                    return (
                                        <div key={listKey} className="results-list-item">
                                            <div className="name">
                                                <Tooltip placement="topLeft" title={`${studentFName} ${studentLName.substr(0, 1)}.`}>
                                                    <Text ellipsis={true}>
                                                        {`${studentFName} ${studentLName.substr(0, 1)}.`}
                                                    </Text>
                                                </Tooltip>

                                            </div>
                                            <div className="grade">
                                                <Tooltip placement="topLeft" title={gradeName}>
                                                    <Text ellipsis={true}>
                                                        {gradeName}
                                                    </Text>
                                                </Tooltip>

                                            </div>
                                            <div className="state">
                                                <Tooltip placement="topLeft" title={province}>
                                                    <Text ellipsis={true}>
                                                        {province}
                                                    </Text>
                                                </Tooltip>
                                            </div>
                                            <div className="center">
                                                <Tooltip placement="topLeft" title={centerName}>
                                                    <Text ellipsis={true}>
                                                        {centerName}
                                                    </Text>
                                                </Tooltip>
                                            </div>
                                            <div className="rank">
                                                <Tooltip placement="topLeft" title={rankForFront}>
                                                    <Text ellipsis={true}>
                                                        {rankForFront}
                                                    </Text>
                                                </Tooltip>
                                            </div>
                                            <div className="award">
                                                <Tooltip placement="topLeft" title={award}>
                                                    <Text ellipsis={true}>
                                                        {award}
                                                    </Text>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    ) : (
                        <div className="results-list-empty">
                            No results available yet
                        </div>
                    )}

                </div>
            </div>
        </>
    );
}

export default Results;
