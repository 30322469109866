/* eslint-disable no-template-curly-in-string */
import React from 'react';
import {Router, Route, Switch, Redirect} from "react-router-dom";
import routes from "./routes";
import {
    history,
} from "./_helpers";
import Header from "./components/layout/header";
import Footer from "./components/layout/footer";
import './Assets/css/style.css'

const App = () => {

    return (
        <>
            <Router history={history}>
                <Header/>
                <Switch>
                    {routes.map((route, idx) => {
                        return route.component ? (
                            <Route
                                key={idx}
                                path={route.path}
                                exact={route.exact}
                                name={route.name}
                                render={props => (
                                    <route.component {...props} key={new Date().getTime()}/>
                                )}
                            />
                        ) : null;
                    })}

                    <Redirect to={{pathname: `/not-found`}}/>
                </Switch>
                <Footer/>
            </Router>

        </>
    );
}

export default App;
