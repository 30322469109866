/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import Slider from "react-slick";
import Lightbox from "react-image-lightbox";
import 'react-image-lightbox/style.css';

const apiUrl = process.env.REACT_APP_API;

const GalleryView = () => {
    const [state, setState] = useState({
        loader: true,
        GalleryList: [],
        showLightBox: false,
        photoIndex: 0,
    })
    useEffect(() => {

        getGallery().then(res => {
            const {payload, code} = res

            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        GalleryList: payload,
                        loader: false,
                    }
                })
            }
        })

        return () => {
            document.body.className = '';
        }
    }, []);

    const getGallery = async () => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const res = await axios.get(`${apiUrl}/web/gallery`)

        const {data} = res

        return {
            code: 200,
            payload: data.payload
        }
    }

    const handleImageClick = index => {
        setState(prevState => {
            return {
                ...prevState,
                photoIndex: index,
                showLightBox: true
            }
        })
    }

    const SliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            }
        ]
    };

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div className={className}
                 style={{...style}}
                 onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <path id="arrowalt"
                          d="M21.649,1.649a20,20,0,1,0,20,20,20,20,0,0,0-20-20ZM18.436,35.033,13.57,30.162l8.512-8.513-8.511-8.518,4.865-4.866L31.818,21.649,18.436,35.032Z"
                          transform="translate(-1.649 -1.649)" fill="#2414bf"/>
                </svg>
            </div>

        )
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div className={className}
                 style={{...style}}
                 onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <path id="arrowalt"
                          d="M21.649,1.649a20,20,0,1,0,20,20,20,20,0,0,0-20-20ZM18.436,35.033,13.57,30.162l8.512-8.513-8.511-8.518,4.865-4.866L31.818,21.649,18.436,35.032Z"
                          transform="translate(41.649 41.649) rotate(180)" fill="#2414bf"/>
                </svg>
            </div>
        )
    }

    const {
        loader, GalleryList, showLightBox, photoIndex
    } = state

    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <section className="gallery-box" id="gallery">
                <div className="gallery-box-bg"/>
                <div className="content-wrapper">
                    <h2>Gallery</h2>
                    <div className="slider-box">
                        <Slider {...SliderSettings}>
                            {Object.keys(GalleryList).map(GalleryKey => {
                                const {url, descr} = GalleryList[GalleryKey]

                                return (
                                    <div className="slider-item" key={GalleryKey}>
                                        <div className="image-wrap">
                                            <img src={url} alt={descr} onClick={() => {
                                                handleImageClick(GalleryKey)
                                            }}/>
                                        </div>
                                    </div>
                                )
                            })}
                        </Slider>
                    </div>
                </div>
            </section>

            {showLightBox && (
                <Lightbox
                    enableZoom={false}
                    mainSrc={GalleryList[photoIndex].url}
                    nextSrc={GalleryList[(photoIndex + 1) % GalleryList.length].url}
                    prevSrc={GalleryList[(photoIndex + GalleryList.length - 1) % GalleryList.length].url}
                    onMovePrevRequest={() =>
                        setState(prevState => {
                            return {
                                ...prevState,
                                photoIndex: (photoIndex + GalleryList.length - 1) % GalleryList.length,
                            }
                        })
                    }
                    onMoveNextRequest={() =>
                        setState(prevState => {
                            return {
                                ...prevState,
                                photoIndex: (photoIndex + 1) % GalleryList.length,
                            }
                        })

                    }
                    onCloseRequest={() => setState(prevState => {
                        return {
                            ...prevState,
                            showLightBox: false,
                            photoIndex: 0
                        }
                    })}
                />
            )}
        </>
    )
}

export default GalleryView;
