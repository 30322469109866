/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';
import MetaTags from "../../../metaTags";
import RegistrationView from "./registrationView";
import JoinView from "./joinView";
import AboutView from "./aboutView";
import InfoView from "./infoView";
import GalleryView from "./galleryView";
import SponsorsView from "./sponsorsView";
import MainView from "./mainView";

function Home() {

    useEffect(() => {
        document.body.className = 'page-home';
        return () => {
            document.body.className = '';
        }
    }, []);

    return (
        <>
            <MetaTags page="home"/>

            <MainView/>
            <RegistrationView/>
            <JoinView/>
            <AboutView/>
            <GalleryView/>
            <InfoView/>
            <SponsorsView/>

        </>
    )
}

export default Home;
