/* eslint-disable no-template-curly-in-string */
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {Provider} from 'react-redux'
import {store} from "./_helpers";

ReactDOM.render(
    <Provider store={store}>
        <App/>
    </Provider>,
    document.getElementById('spacepandamath-website')
);
serviceWorker.unregister();
