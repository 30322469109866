/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import Pagination from 'antd/lib/pagination';
import Tooltip from 'antd/lib/tooltip';
import Typography from 'antd/lib/typography';
import axios from "axios";
import Input from "antd/lib/input";

const {Search} = Input;
const apiUrl = process.env.REACT_APP_API;
const {Text} = Typography

function AllLearningCenters() {
    const loaderBox = document.getElementById('transparent-loader-box');
    const [state, setState] = useState({
        errorMessage: '',
        loader: true,
        list: [],
        listTotal: 0,
        currentPage: 1,
        searchValue: '',
        pp: 20,
    })

    useEffect(() => {
        document.body.className = 'page-invited-students';
        getCenterList().then(res => {
            let {code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: payload.list,
                        listTotal: payload.count
                    }
                })
            } else {

            }
        })
        return () => {
            document.body.className = '';
        }
    }, []);

    const getCenterList = async (start = 0, end = 20, search = '') => {
        const queryParams = {
            _start: start,
            _end: end,
            search
        };
        const res = await axios.get(`${apiUrl}/web/centers/`, {
            params: queryParams,
        })

        const {data} = res;
        if (data.error === null) {
            let {rows, count} = data.payload
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: 200,
                payload: {
                    count,
                    list: rows,
                }
            };
        } else {
            setState(prevState => {
                return {
                    ...prevState,
                    loader: false,
                }
            })

            return {
                code: data.error.code,
                message: data.error.message
            };
        }
    }

    const onChange = page => {
        const {pp, searchValue} = state;

        let start = (page - 1) * pp,
            end = pp * page;
        loaderBox.style.display = 'flex'

        setState(prevState => {
            return {
                ...prevState,
                currentPage: page,
            }
        })

        getCenterList(start, end, searchValue).then(res => {
            let {code, payload} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: payload.list,
                        listTotal: payload.count
                    }
                })
                loaderBox.style.display = 'none'
            }
        })
    }

    const onSearch = value => {
        let {pp} = state;
        loaderBox.style.display = 'flex'
        setState(prevState => {
            return {
                ...prevState,
                searchValue: value,
            }
        })

        getCenterList(0, pp, value).then(res => {
            if (res.code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        list: res.payload.list,
                        listTotal: res.payload.count,
                        currentPage: 1
                    }
                })
                loaderBox.style.display = 'none'
            }
        })
    }

    const {loader, currentPage, list, listTotal, pp} = state
    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}
            <div id="transparent-loader-box" className="loader-box transparent" style={{display: 'none'}}>
                <div className="atom">
                    <div className="electron"/>
                    <div className="electron"/>
                    <div className="electron"/>
                </div>
            </div>
            <div className="all-container">
                <div className="content-wrapper">
                    <h2>All Learning Centers</h2>
                    <div className="field-box">
                        <Search
                            autoComplete={new Date().valueOf()}
                            placeholder="Search by Center Name and Zip code"
                            onSearch={onSearch}/>
                    </div>
                    {listTotal > 0 ? (
                        <div className="center-list">
                            {Object.keys(list).map(listKey => {
                                let {
                                    address1,
                                    address2,
                                    centerName,
                                    city,
                                    zipCode,
                                    state,
                                    countryName,
                                    website
                                } = list[listKey]

                                if (website) {
                                    return (
                                        <a href={website} target="_blank" rel="noopener noreferrer"
                                           aria-label={centerName} key={listKey} className="center-list-item">
                                            <div className="name">
                                                <Tooltip placement="topLeft" title={centerName}>
                                                    <Text ellipsis={true}>
                                                        {centerName}
                                                    </Text>
                                                </Tooltip>
                                            </div>

                                            <div className="address">
                                                <Tooltip placement="topLeft"
                                                         title={`${address1} ${address2 ? `, ${address2}` : ','} ${city}, ${state} ${zipCode}, ${countryName}`}>
                                                    <Text
                                                        ellipsis={true}>{`${address1} ${address2 ? `, ${address2}` : ','} ${city}, ${state} ${zipCode}, ${countryName}`}</Text>
                                                </Tooltip>
                                            </div>

                                        </a>
                                    )
                                } else {
                                    return (
                                        <div key={listKey} className="center-list-item">
                                            <div className="name">
                                                <Tooltip placement="topLeft" title={centerName}>
                                                    <Text ellipsis={true}>
                                                        {centerName}
                                                    </Text>
                                                </Tooltip>
                                            </div>

                                            <div className="address">
                                                <Tooltip placement="topLeft"
                                                         title={`${address1} ${address2 ? `, ${address2}` : ','} ${city}, ${state} ${zipCode}, ${countryName}`}>
                                                    <Text
                                                        ellipsis={true}>{`${address1} ${address2 ? `, ${address2}` : ','} ${city}, ${state} ${zipCode}, ${countryName}`}</Text>
                                                </Tooltip>
                                            </div>

                                        </div>
                                    )
                                }

                            })}
                        </div>
                    ) : (
                        <div className="center-list-empty">
                            No Learning Center yet
                        </div>
                    )}
                    {listTotal > 0 ? (
                        <Pagination
                            size="small"
                            defaultPageSize={pp}
                            showSizeChanger={false}
                            className="ant-table-pagination ant-table-pagination-center"
                            current={currentPage}
                            onChange={onChange}
                            total={listTotal}
                        />
                    ) : null}
                </div>
            </div>
        </>
    );
}

export default AllLearningCenters;
