/* eslint-disable no-template-curly-in-string */
import React, {useState, useEffect} from 'react';
import MetaTags from "../../../metaTags";

import NotFoundImage from "../../../Assets/images/not-found.png"
function NotFound() {
    const [, forceUpdate] = useState({});

    useEffect(() => {
        document.body.className = 'page-not-found';
        window.scroll(0, 0);
        forceUpdate({});
        return () => {
            document.body.className = '';
        }
    }, []);

    return (
        <>
            <MetaTags page="notFound"/>
            <div className="page-container">
                <div className="content-wrapper">
                    <div className="left">
                        <img src={NotFoundImage} alt=""/>
                    </div>

                    <div className="right">
                        <h1>OOPS, something
                            went wrong!</h1>
                        <div className="text">The requested page not found</div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default NotFound;
