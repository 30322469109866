/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';
import Saturn from "../../../../Assets/svg/saturn.svg";
import Earth from "../../../../Assets/svg/earth.svg";

const centerUrl = process.env.REACT_APP_CENTER_URL;
const studentUrl = process.env.REACT_APP_STUDENT_URL;
const JoinView = () => {

    useEffect(() => {

        return () => {
            document.body.className = '';
        }
    }, []);


    return (
        <section className="join-box">
            <div className="content-wrapper">
                <div className="item">
                    <div className="icon">
                        <img src={Saturn} alt=""/>
                    </div>
                    <div className="title">
                        Learning Center
                    </div>
                    <div className="description">
                        Join the growing list of learning centers to host the coolest math olympiad of 2021
                    </div>
                    <a href={`${centerUrl}/register`} target="_blank" rel="noopener noreferrer"
                       className="btn btn-medium whit-border border-color-pink color-white bg-pink">Join</a>
                </div>

                <div className="item">
                    <div className="icon">
                        <img src={Earth} alt=""/>
                    </div>
                    <div className="title">
                        Students
                    </div>
                    <div className="description">
                        Become the first in your class to join our Panda in exploring the Space with math
                    </div>
                    <a href={`${studentUrl}/register`} target="_blank" rel="noopener noreferrer"
                       className="btn btn-medium whit-border border-color-pink color-white bg-pink">Join</a>
                </div>
            </div>
        </section>
    )
}

export default JoinView;
