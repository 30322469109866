/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';
const InfoView = () => {

    useEffect(() => {

        return () => {
            document.body.className = '';
        }
    }, []);


    return (
        <section className="info-box">
            <div className="content-wrapper">
                <h2>Why Join The Olympiad</h2>

                <div className="info-list">
                    <div className="info-list-item">
                        <div className="icon">

                            <svg xmlns="http://www.w3.org/2000/svg" width="58.195" height="65.481"
                                 viewBox="0 0 58.195 65.481">
                                <g id="_4236767" data-name="4236767" transform="translate(-28.486)">
                                    <path id="Path_3505" data-name="Path 3505"
                                          d="M86.682,24A12.635,12.635,0,0,0,74.717,11.4,9.606,9.606,0,0,0,57.585,3.854,9.606,9.606,0,0,0,40.43,11.269a12.629,12.629,0,0,0-8.356,21.408,12.628,12.628,0,0,0,8.379,21.409,9.606,9.606,0,0,0,17.132,7.542A9.606,9.606,0,0,0,74.74,54.213a12.635,12.635,0,0,0,11.943-12.6,12.82,12.82,0,0,0-3.574-8.92A12.245,12.245,0,0,0,86.682,24ZM42.818,15.255a1.92,1.92,0,0,0,2.043-2.823A5.772,5.772,0,1,1,55.666,9.608v4.106a6.725,6.725,0,1,0,0,13.059v3.985H49.407a2.431,2.431,0,0,0-2,3.808,2.888,2.888,0,1,1-4.763,0,2.431,2.431,0,0,0-2-3.808H35.665a8.78,8.78,0,0,1,7.153-15.5Zm7.076,46.389a5.778,5.778,0,0,1-4.971-8.7,1.92,1.92,0,0,0-2.037-2.856A8.781,8.781,0,0,1,35.664,34.6h2.827a6.725,6.725,0,1,0,13.061,0h4.114v6.13a2.432,2.432,0,0,0,3.808,2,2.888,2.888,0,1,1,0,4.762,2.431,2.431,0,0,0-3.808,2v6.382a5.779,5.779,0,0,1-5.772,5.771ZM72.351,50.226a1.92,1.92,0,0,0-2.043,2.823A5.772,5.772,0,1,1,59.5,55.874V51.636a6.725,6.725,0,1,0,0-13.059V34.6h6.259a2.431,2.431,0,0,0,2-3.808,2.888,2.888,0,1,1,4.763,0,2.431,2.431,0,0,0,2,3.808H79.5a9.064,9.064,0,0,1,3.342,7.018A8.791,8.791,0,0,1,72.351,50.226ZM79.52,30.758H76.675a6.725,6.725,0,1,0-13.061,0H59.5v-6.13a2.431,2.431,0,0,0-3.808-2,2.889,2.889,0,1,1,0-4.762,2.432,2.432,0,0,0,3.808-2V9.608A5.772,5.772,0,1,1,70.247,12.54,1.92,1.92,0,0,0,72.284,15.4,8.794,8.794,0,0,1,82.845,24a8.519,8.519,0,0,1-3.325,6.761Z"
                                          transform="translate(0 0)" fill="#fff"/>
                                </g>
                            </svg>
                        </div>
                        <div className="title">Boosts Logical Thinking</div>
                        <div className="description">We develop problem-solving approaches in kids not typically
                            covered in school through language of logic
                        </div>
                    </div>

                    <div className="info-list-item">
                        <div className="icon">
                            <svg id="_981423" data-name="981423" xmlns="http://www.w3.org/2000/svg" width="65.555"
                                 height="65.555" viewBox="0 0 65.555 65.555">
                                <g id="Group_1870" data-name="Group 1870">
                                    <g id="Group_1869" data-name="Group 1869">
                                        <path id="Path_3507" data-name="Path 3507"
                                              d="M120,85.1a17.446,17.446,0,0,0,11.655,16.435,1.921,1.921,0,1,0,1.273-3.624,13.573,13.573,0,0,1,4.485-26.382,13.414,13.414,0,0,1,5.881,1.342,1.92,1.92,0,1,0,1.671-3.459,17.179,17.179,0,0,0-5.631-1.62V63.93A21.31,21.31,0,0,1,158.144,80.4a1.921,1.921,0,1,0,3.746-.848,25.087,25.087,0,0,0-37.86-15.677,1.921,1.921,0,1,0,2.054,3.246,21.134,21.134,0,0,1,9.408-3.188v3.858A17.437,17.437,0,0,0,120,85.1Z"
                                              transform="translate(-104.636 -52.318)" fill="#fff"/>
                                        <path id="Path_3508" data-name="Path 3508"
                                              d="M108.124,162.021a1.921,1.921,0,0,0-2.3,1.449,21.284,21.284,0,0,1-4.416,8.9l-2.727-2.727a17.4,17.4,0,0,0,3.824-10.873,17.22,17.22,0,0,0-1.724-7.552,1.921,1.921,0,1,0-3.459,1.671,13.372,13.372,0,0,1,1.208,3.961h-3.9a9.731,9.731,0,1,0,0,3.841h3.893a13.606,13.606,0,0,1-8.948,10.89,1.921,1.921,0,1,0,1.273,3.624,17.377,17.377,0,0,0,5.117-2.844L98.7,175.09a21.244,21.244,0,0,1-31.577-27.647,1.921,1.921,0,0,0-3.246-2.054,25.093,25.093,0,1,0,45.7,18.928A1.921,1.921,0,0,0,108.124,162.021ZM85.1,164.662a5.89,5.89,0,1,1,5.89-5.89A5.9,5.9,0,0,1,85.1,164.662Z"
                                              transform="translate(-52.318 -125.995)" fill="#fff"/>
                                        <path id="Path_3509" data-name="Path 3509"
                                              d="M32.777,0A32.777,32.777,0,1,0,65.555,32.777,32.76,32.76,0,0,0,32.777,0Zm0,61.714A28.936,28.936,0,1,1,61.714,32.777,28.921,28.921,0,0,1,32.777,61.714Z"
                                              fill="#fff"/>
                                    </g>
                                </g>
                            </svg>
                        </div>
                        <div className="title">Advances Perceptive Problem Solving</div>
                        <div className="description">We boosts students ability to think fast, on their feet, and
                            apply it in everyday life
                        </div>
                    </div>

                    <div className="info-list-item">
                        <div className="icon">
                            <svg id="_4511928" data-name="4511928" xmlns="http://www.w3.org/2000/svg" width="55.852"
                                 height="55.852" viewBox="0 0 55.852 55.852">
                                <path id="Path_3506" data-name="Path 3506"
                                      d="M49.281,0V9.856H42.389a4.38,4.38,0,1,0,0,3.285h6.892v11.5H39.1a4.38,4.38,0,1,0,0,3.285H49.281v11.5H42.389a4.381,4.381,0,1,0,0,3.285h6.892v9.856H6.571V42.71H16.748a4.381,4.381,0,1,0,0-3.285H6.571v-11.5h6.892a4.38,4.38,0,1,0,0-3.285H6.571v-11.5H16.748a4.38,4.38,0,1,0,0-3.285H6.571V0H0V55.852H55.852V0Z"
                                      fill="#fff"/>
                                <circle id="Ellipse_345" data-name="Ellipse 345" cx="4.431" cy="4.431" r="4.431"
                                        transform="translate(25.199 6.791)" fill="#fff"/>
                                <circle id="Ellipse_346" data-name="Ellipse 346" cx="4.431" cy="4.431" r="4.431"
                                        transform="translate(21.79 21.79)" fill="#fff"/>
                                <circle id="Ellipse_347" data-name="Ellipse 347" cx="4.431" cy="4.431" r="4.431"
                                        transform="translate(25.199 36.789)" fill="#fff"/>
                            </svg>

                        </div>
                        <div className="title">Fosters Creativity</div>
                        <div className="description">We enrich students’ creative side via visual math which helps
                            to develop the right part of the brain responsible for creativity
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default InfoView;
