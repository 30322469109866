/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";

const apiUrl = process.env.REACT_APP_API;

function TopLearningCenters() {
    const [state, setState] = useState({
        loader: true,
        FeaturedList: [],
    })
    useEffect(() => {

        getGallery().then(res => {
            const {payload, code} = res

            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        FeaturedList: payload,
                        loader: false,
                    }
                })
            }
        })

        return () => {
            document.body.className = '';
        }
    }, []);

    const getGallery = async () => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const res = await axios.get(`${apiUrl}/web/featuredcenters/`)

        const {data} = res

        return {
            code: 200,
            payload: data.payload
        }
    }

    const {loader, FeaturedList} = state

    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}


            {FeaturedList.length ? (
                <>
                    <div className="top-container">
                        <div className="content-wrapper">
                            <h2>Top Learning Centers</h2>
                            <div className="featured-list">
                                {Object.keys(FeaturedList).map(FeaturedKey => {
                                    const {url, subtitle, title, imageUrl} = FeaturedList[FeaturedKey]
                                    if (url) {
                                        return (
                                            <a href={url} target="_blank" rel="noopener noreferrer"
                                               aria-label={title} className="featured-item" key={FeaturedKey}>
                                                <div className="featured-icon">
                                                    <img src={imageUrl} alt={subtitle}/>
                                                </div>
                                                <div className="title">{title}</div>
                                                <div className="description"
                                                     dangerouslySetInnerHTML={{__html: subtitle.replace(/(\r\n|\n|\r)/gm, "<br />")}}/>
                                            </a>
                                        )
                                    } else {
                                        return (
                                            <div className="featured-item" key={FeaturedKey}>
                                                <div className="featured-icon">
                                                    <img src={imageUrl} alt={subtitle}/>
                                                </div>
                                                <div className="title">{title}</div>
                                                <div className="description"
                                                     dangerouslySetInnerHTML={{__html: subtitle.replace(/(\r\n|\n|\r)/gm, "<br />")}}/>
                                            </div>
                                        )
                                    }
                                })}
                            </div>
                        </div>
                    </div>
                </>
            ) : ''}

        </>
    )
}

export default TopLearningCenters;
