/* eslint-disable no-template-curly-in-string */
import React, {useEffect, useState} from 'react';
import axios from "axios";
import Slider from "react-slick";
import Sponsor1 from "../../../../Assets/svg/sponsors/1.svg";
import Sponsor2 from "../../../../Assets/svg/sponsors/2.svg";
import Sponsor3 from "../../../../Assets/svg/sponsors/3.svg";
import Sponsor4 from "../../../../Assets/svg/sponsors/4.svg";
const apiUrl = process.env.REACT_APP_API;

const SponsorsView = () => {
    const [state, setState] = useState({
        loader: true,
        GalleryList: [],
        showLightBox: false,
        photoIndex: 0,
        SponsorsList: [],
        SponsorsIcon: {
            0: Sponsor1,
            1: Sponsor2,
            2: Sponsor3,
            3: Sponsor4
        },
    })
    useEffect(() => {

        getSponsors().then(res => {
            const {payload, code} = res
            if (code === 200) {
                setState(prevState => {
                    return {
                        ...prevState,
                        SponsorsList: payload,
                        loader: false
                    }
                })
            }
        })

        return () => {
            document.body.className = '';
        }
    }, []);

    const getSponsors = async () => {
        setState(prevState => {
            return {
                ...prevState,
                loader: true
            }
        })
        const res = await axios.get(`${apiUrl}/web/sponsors`)

        const {data} = res

        return {
            code: 200,
            payload: data.payload
        }
    }


    const SponsorsSliderSettings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        responsive: [
            {
                breakpoint: 980,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            },
            {
                breakpoint: 721,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    initialSlide: 0
                }
            }
        ]
    };

    function SampleNextArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div className={className}
                 style={{...style}}
                 onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <path id="arrowalt"
                          d="M21.649,1.649a20,20,0,1,0,20,20,20,20,0,0,0-20-20ZM18.436,35.033,13.57,30.162l8.512-8.513-8.511-8.518,4.865-4.866L31.818,21.649,18.436,35.032Z"
                          transform="translate(-1.649 -1.649)" fill="#2414bf"/>
                </svg>
            </div>

        )
    }

    function SamplePrevArrow(props) {
        const {className, style, onClick} = props;
        return (
            <div className={className}
                 style={{...style}}
                 onClick={onClick}>
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40">
                    <path id="arrowalt"
                          d="M21.649,1.649a20,20,0,1,0,20,20,20,20,0,0,0-20-20ZM18.436,35.033,13.57,30.162l8.512-8.513-8.511-8.518,4.865-4.866L31.818,21.649,18.436,35.032Z"
                          transform="translate(41.649 41.649) rotate(180)" fill="#2414bf"/>
                </svg>
            </div>
        )
    }

    const {
        loader, SponsorsList, SponsorsIcon
    } = state

    let SponsorIndex = 0

    return (
        <>
            {loader ? (
                <div id="loader-box" className="loader-box">
                    <div className="atom">
                        <div className="electron"/>
                        <div className="electron"/>
                        <div className="electron"/>
                    </div>
                </div>
            ) : null}

            <section className="sponsors-box" id="sponsors">
                <div className="content-wrapper">
                    <h2>Sponsors</h2>


                    {SponsorsList.length > 4 ? (
                        <div className="sponsors-list sponsors-list-slider">
                            <Slider {...SponsorsSliderSettings}>
                                {Object.keys(SponsorsList).map(SponsorsKey => {
                                    const {url, descr} = SponsorsList[SponsorsKey]

                                    if (parseInt(SponsorsKey) === 4) {
                                        SponsorIndex = 0
                                    }
                                    SponsorIndex++
                                    return (
                                        <div className="sponsors-item" key={SponsorsKey}>
                                            <div className="sponsors-icon"><img src={SponsorsIcon[SponsorIndex - 1]}
                                                                                alt={descr}/></div>
                                            <div className="sponsors-logo"><a href={descr} target="_blank" rel="noopener noreferrer"><img src={url} alt={descr}/></a></div>
                                        </div>
                                    )
                                })}
                            </Slider>
                        </div>

                    ) : (
                        <div className="sponsors-list">
                            {Object.keys(SponsorsList).map(SponsorsKey => {
                                const {url, descr} = SponsorsList[SponsorsKey]

                                if (parseInt(SponsorsKey) === 4) {
                                    SponsorIndex = 0
                                }
                                SponsorIndex++
                                return (
                                    <div className="sponsors-item" key={SponsorsKey}>
                                        <div className="sponsors-icon"><img src={SponsorsIcon[SponsorIndex - 1]}
                                                                            alt={descr}/></div>
                                        <div className="sponsors-logo"><a href={descr} target="_blank" rel="noopener noreferrer"><img src={url} alt={descr}/></a></div>
                                    </div>
                                )
                            })}
                        </div>
                    )}

                </div>
            </section>
        </>
    )
}

export default SponsorsView;
