/* eslint-disable no-template-curly-in-string */
import React, {useEffect} from 'react';
import AllLearningCenters from "./allLerningCenters";
import TopLearningCenters from "./topLerningCenters";

function LearningCenters() {

    useEffect(() => {
        document.body.className = 'page-learning-centers';
        return () => {
            document.body.className = '';
        }
    }, []);

    return (
        <div className="page-container" >
            <TopLearningCenters/>
            <AllLearningCenters/>
        </div>
    )
}

export default LearningCenters;
