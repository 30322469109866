/* eslint-disable no-template-curly-in-string */
import NotFound from "./components/pages/notfound";
import Home from "./components/pages/home";
import ResultsOlympSummer2021 from "./components/pages/resultsOlympSummer2021"
import ResultsCountingMethodsComp2022 from "./components/pages/countingMethodsComp2022"
import LearningCenters from "./components/pages/learningCenters";
const routes = [
    {
        path: `/`,
        pathname: "/",
        exact: true,
        name: "Home",
        id: "Home",
        component: Home
    },
    {
        path: `/not-found`,
        pathname: "/not-found",
        exact: true,
        name: "Not Found",
        id: "notFound",
        component: NotFound
    },
    {
        path: `/results-olymp-summer-2021`,
        pathname: "/results-olymp-summer-2021",
        exact: true,
        name: "Results Olympiad Summer 2021",
        id: "results-olymp-summer-2021",
        component: ResultsOlympSummer2021
    },
    {
        path: `/results-counting-methods-comp-2022`,
        pathname: "/results-counting-methods-comp-2022",
        exact: true,
        name: "Results Counting Methods Competition 2022",
        id: "results-counting-methods-comp-2022",
        component: ResultsCountingMethodsComp2022
    },
    {
        path: `/centers`,
        pathname: "/centers",
        exact: true,
        name: "Centers",
        id: "centers",
        component: LearningCenters
    }
];

export default routes;
